import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const EasyCallReport = React.lazy(() => import('./views/easycallreport/EasyCallReport'))
const MigrateECR = React.lazy(() => import('./views/easycallreport/MigrateECR'))
const EasyDirectory = React.lazy(() => import('./views/easydirectory/EasyDirectory'))
const SubscriptionManager = React.lazy(() => import('./views/subscriptions/SubscriptionManager'))
const ManageSubscription = React.lazy(() => import('./views/subscriptions/ManageSubscription'))
const SubscriptionOnboarding = React.lazy(() => import('./views/subscriptions/Onboarding'))
const EasyCallReportOnboarding = React.lazy(() => import('./views/subscriptions/onboarding/EasyCallReport'))
const EasyDirectoryOnboarding = React.lazy(() => import('./views/subscriptions/onboarding/EasyDirectory'))
const HomePageLanding = React.lazy(() => import('./views/pages/HomePageLanding'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/easycallreport', name: 'Easy Call Report', element: EasyCallReport, exact: true },
  { path: '/easydirectory', name: 'Easy Directory', element: EasyDirectory, exact: true },
  { path: '/subscriptions', name: 'Subscription Manager', exact: true, element: SubscriptionManager },
  { path: '/subscriptions/:subscriptionId', name: 'Subscription Manager', exact: true, element: ManageSubscription },
  { path: '/subscriptions/onboarding', name: 'Subscription Onboarding', exact: true, element: SubscriptionOnboarding },
  { path: '/subscriptions/onboarding/easycallreport', name: 'Easy Call Report Onboarding', exact: true, element: EasyCallReportOnboarding },
  { path: '/subscriptions/easycallreport/migrate', name: 'Easy Call Report Migration', exact: true, element: MigrateECR },
  { path: '/subscriptions/onboarding/easydirectory', name: 'Easy Directory Onboarding', exact: true, element: EasyDirectoryOnboarding },
  { path: '/store/landing', name: 'Subscription Onboarding', exact: true, element: SubscriptionOnboarding },
  { path: '/landing', name: 'My Subscriptions', exact: true, element: HomePageLanding },
]

export default routes
