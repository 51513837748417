export const APIM = 'https://api.dev.easyplatform.app/'
export const APIMHostname = 'api.dev.easyplatform.app'
export const NotificationUrl = 'https://api.dev.easyplatform.app/easycallreport/callrecord'
export const Scopes = ['https://graph.microsoft.com/.default']
export const ElevatedScope = '48ac35b8-9aa8-4d74-927d-1f4a14a0b239/user_impersonation'
export const RedirectUrl = 'https://admin.dev.easyplatform.app/' // LOCAL: 'http://localhost:3000/' DEV: 'https://admin.dev.easyplatform.app/' PROD: 'https://admin.easyplatform.app/'
export const ApplicationId = '7946a958-736c-4fb2-a93d-156284a98850'
export const EDApplicationId = '8cc09c62-f3d1-44f8-a702-52679b0dd654'
export const ApplicationScopes = [
  'Channel.ReadBasic.All',
  'ChannelSettings.Read.All',
  'ChannelSettings.ReadWrite.All',
  'Group.Read.All',
  'Tasks.ReadWrite',
]
export const AdminApplicationId = '5db2d34e-0de9-4a08-a5ff-f504efd6c083' //DEV: "5db2d34e-0de9-4a08-a5ff-f504efd6c083"  PROD: "1825119b-4293-4a92-8a45-24ba908a24ed"
export const ChargebeeApiKey = 'test_cuIRsL3S29PWBSVyCg9W4Hkkm9qqlb3OD'
// export const ChargebeeApiKey = "test_hAYwEKFTRYJxqK5mMmunJ6QT6LXzHV1t"
export const ChargebeeURL = 'https://twincapfirst1-test.chargebee.com/api/v2/'
// export const ChargebeeApiKey = "test_cuIRsL3S29PWBSVyCg9W4Hkkm9qqlb3OD"
export const Version = '2.5'

