import axios, { AxiosError } from "axios";
import { APIM, ChargebeeApiKey } from "src/common/constants";
import { ChargebeeCustomer, ChargebeeSubscription, Subscription } from "src/common/interfaces";

export class SubscriptionService {
    constructor(access_token?: string) {
        if (access_token !== undefined && access_token !== null) {
            if (access_token.startsWith("Bearer ")) {
                this.access_token = access_token;
            }
            else {
                this.access_token = "Bearer " + access_token;
            }
        }
    }

    access_token: string;

    getSubscriptionStatus(status_id: number): string {
        switch (status_id) {
            case 0:
                return "Unknown";
            case 1:
                return "Pending Confirmation";
            case 2:
                return "Pending Activation";
            case 3:
                return "Active";
            case 4:
                return "Suspended";
            case 5:
                return "Cancelled";
            case 6:
                return "Purchased";
            default:
                return "Unknown";
        }
    }

    getSubscriptionProductTitle(offer_id: string): string {
        switch (offer_id) {
            case "easycallreport_dev-preview":
            case "easycallreport_dev":
            case "easycallreport-preview":
            case "easycallreport":
            case "EasyCallReport-Basic-CHF-Yearly":
            case "EasyCallReport-Basic-EUR-Yearly":
            case "EasyCallReport-Pro-CHF-Yearly":
            case "EasyCallReport-Pro-EUR-Yearly":
            case "EasyCallReport-Enterprise-CHF-Yearly":
            case "EasyCallReport-Enterprise-EUR-Yearly":
            case "Easy-Call-Report-NFR-CHF-Monthly":
            case "Easy-Call-Report-NFR-EUR-Monthly":
            case "EasyCallReport-NFR-CHF-Monthly":
            case "EasyCallReport-NFR-EUR-Monthly":
                return "Easy Call Report";
            case "TeamsEasyCallReport-Standard-CHF-Yearly":
                return "Easy Call Report (deprecated)";
            case "TeamsEasyCallReport-Standard-EUR-Yearly":
                return "Easy Call Report (deprecated)";
            default:
                return "Unknown";
        }
    }

    getSubscriptionProductPlan(plan_id: string): string {
        switch (plan_id) {
            case "ecr_basic":
            case "basic":
            case "basic_plan":
            case "EasyCallReport-Basic-CHF-Yearly":
            case "EasyCallReport-Basic-EUR-Yearly":
            case "TeamsEasyCallReport-Standard-CHF-Yearly":
            case "TeamsEasyCallReport-Standard-EUR-Yearly":
                return "Basic";
            case "pro":
            case "pro_plan":
            case "EasyCallReport-Pro-CHF-Yearly":
            case "EasyCallReport-Pro-EUR-Yearly":
                return "Professional";
            case "Easy-Call-Report-NFR-CHF-Monthly":
            case "Easy-Call-Report-NFR-EUR-Monthly":
            case "EasyCallReport-NFR-CHF-Monthly":
            case "EasyCallReport-NFR-EUR-Monthly":
                return "NFR";
            case "enterprise":
            case "enterprise_plan":
            case "EasyCallReport-Enterprise-CHF-Yearly":
            case "EasyCallReport-Enterprise-EUR-Yearly":
                return "Enterprise";
            default:
                return "Unknown";
        }
    }

    checkDeprecatedEcr(plan_id: string): boolean {
        switch (plan_id) {
            case "TeamsEasyCallReport-Standard-CHF-Yearly":
            case "TeamsEasyCallReport-Standard-EUR-Yearly":
                return true;
            default:
                return false;
        }
    }

    async getSubscriptionFromToken(store_token: string): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions/landing?token=' + encodeURIComponent(store_token), {
            method: 'GET'
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async postNewSubscription(store_token: string, subscription: Subscription): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions', {
            method: 'POST',
            headers: {
                'Authorization': store_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subscription)
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async patchSubscription(subscriptionId: string, subscription: Subscription): Promise<Subscription> {
        const response = await fetch(APIM + 'subscriptions/' + subscriptionId, {
            method: 'PATCH',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(subscription)
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res as Subscription ?? null;
        }

        return null;
    }

    async getSubscriptions(): Promise<Subscription[]> {
        const response = await fetch(APIM + 'subscriptions', {
            method: 'GET',
            headers: {
                'Authorization': this.access_token,
                'Content-Type': 'application/json'
            }
        }).catch(console.log)

        if (response) {
            const res = await response.json();

            return res ?? null;
        }

        return null;
    }

    IsReseller = (subscriptions: Subscription[]) => {
        let isReseller = false;

        subscriptions.forEach((subscription: Subscription) => {
            if (subscription.offer_id.includes("Easy-Call-Report-NFR") === true || subscription.offer_id.includes("EasyCallReport-NFR") === true || (subscription?.customer?.isReseller === true && subscription?.customer?.tenantId === subscription?.tenant_id)) {
                isReseller = true;
            }
        });

        return isReseller;
    }

    async getResellerSubscriptions(resellerId: string): Promise<Subscription[]> {
        let response;

        if (resellerId !== undefined && resellerId !== null) {
            response = await fetch(APIM + 'reseller/subscriptions', {
                method: 'GET',
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.log)
        } else {
            response = await fetch(APIM + 'subscriptions', {
                method: 'GET',
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            }).catch(console.log)
        }


        if (response) {
            const res = await response.json();

            return res ?? null;
        }

        return null;
    }

    async assignResellerSubscription(subscriptionId: string, tenantId: string): Promise<Subscription> {

        if (subscriptionId === undefined || subscriptionId === null || subscriptionId === "" || tenantId === undefined || tenantId === null || tenantId === "") {
            throw new Error("Missing Parameters");
        }

        try {
            const response = await axios.post(APIM + 'reseller/subscriptions/' + subscriptionId + '/assign', {
                tenant: tenantId
            },
                {
                    headers: {
                        'Authorization': this.access_token,
                        'Content-Type': 'application/json'
                    }
                })

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async getSubscription(subscriptionId: string): Promise<Subscription> {
        try {
            const response = await axios.get(APIM + 'subscriptions/' + subscriptionId, {
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            })

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    getEasyCallReportSeatCount(subscriptions: Subscription[]): number {
        let seatCount: number = 0;

        subscriptions.forEach((subscription) => {
            if (subscription.state === 3) {
                if (subscription.offer_id.includes("easycallreport") === true) {
                    seatCount = seatCount + (subscription.seatQuantity * 5); // Multiply with 5 for MS licenses, because we offer 5 call queues per seat
                } else if (subscription.offer_id.includes("Easy-Call-Report-NFR") === true || subscription.offer_id.includes("EasyCallReport-NFR") === true) {
                    seatCount = seatCount + 5; // Reseller are getting 5 licenses
                }
                else if (subscription.offer_id.includes("EasyCallReport") === true) {
                    seatCount = seatCount + subscription.seatQuantity;
                }
            }
        });

        return seatCount;
    }

    isEasyCallReportLicensed(subscriptions: Subscription[]): boolean {
        let isLicensed: boolean = false;

        subscriptions.forEach((subscription) => {
            if (subscription.state === 3) {
                if (subscription.offer_id.includes("easycallreport") === true || subscription.offer_id.includes("EasyCallReport")) {
                    isLicensed = true;
                }
            }
        });

        return isLicensed;
    }
    
    isEasyDirectoryLicensed(subscriptions: Subscription[]): boolean {
        let isLicensed: boolean = false;

        subscriptions.forEach((subscription) => {
            if (subscription.state === 3) {
                if (subscription.offer_id.includes("easydirectory") === true || subscription.offer_id.includes("EasyDirectory")) {
                    isLicensed = true;
                }
            }
        });

        return isLicensed;
    }

    async getChargebeeCustomer(): Promise<ChargebeeCustomer> {
        try {
            const response = await axios.get(APIM + 'licenses/customers/', {
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            })


            return response.data.customer;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async getChargebeeSubscriptions(customerId: string): Promise<ChargebeeSubscription[]> {
        try {
            const response = await axios.get(APIM + 'licenses/customers/' + customerId + '/subscriptions', {
                headers: {
                    'Authorization': this.access_token,
                    'Content-Type': 'application/json'
                }
            })

            return response.data.list;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    async cancelChargebeeSubscription(subscriptionId: string, end_of_term: boolean): Promise<ChargebeeSubscription[]> {
        var cancellationBody = {
            'end_of_term': end_of_term,
            'credit_option_for_current_term_charges': 'prorate'
        }

        // NOTE: Chargebee handles credit_option_for_current_term_charges just in cases, where end_of_term = false

        try {
            const response = await axios.post('https://twincapfirst1.chargebee.com/api/v2/subscriptions/' + subscriptionId + '/cancel_for_items', {
                auth: {
                    username: ChargebeeApiKey
                },
                body: cancellationBody
            })

            return response.data;
        } catch (e) {
            throw new AxiosError(e);
        }
    }

    IsEasyCallReportProLicensed(subscriptions: Subscription[]): boolean {
        try {
            let isLicensed: boolean = false;

            subscriptions.forEach((subscription) => {
                if (subscription.state === 3) {
                    if (subscription.offer_id.includes("easycallreport") === true || subscription.offer_id.includes("EasyCallReport") || subscription.offer_id.includes("Easy-Call-Report")) {
                        if (subscription.plan_id.includes("pro") || subscription.plan_id.includes("enterprise") || subscription.plan_id.includes("EasyCallReport-Pro") || subscription.plan_id.includes("EasyCallReport-Enterprise") || subscription.plan_id.includes("EasyCallReport-NFR")) {
                            isLicensed = true;
                        }
                    }
                }
            });

            return isLicensed;
        } catch (e) {
            console.log(e)
            return false;
        }
    }
    getExpirationDate(date: Date) {
        if (date !== undefined && date !== null) {
            const dateToFormat = new Date(date);
            return new Intl.DateTimeFormat(navigator.languages[0], { year: 'numeric', month: '2-digit', day: '2-digit' }).format(dateToFormat);
        }
        return "";
    }
}