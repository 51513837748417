import { CNavItem } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    // icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    /*badge: {
      color: 'info',
      text: 'NEW',
    },*/
  },
  {
    component: CNavItem,
    name: 'Easy Call Report',
    to: '/easycallreport',
    license: 'easycallreport'
    // icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Easy Directory',
    to: '/easydirectory',
    license: 'easydirectory'
    // icon: <CIcon icon={cilPhone} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Easy Directory',
  //   to: '/easydirectory',
  //   // icon: <CIcon icon={cibViber} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'Easy Contact Sync',
  //   to: '/easycontactsync',
  //   // icon: <CIcon icon={cilLoopCircular} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Subscriptions',
    to: '/subscriptions',
    // icon: <CIcon icon={cilLoopCircular} customClassName="nav-icon" />,
  },
  /*{
  {
    component: CNavTitle,
    name: 'Extras',
  },
  
    component: CNavGroup,
    name: 'Pages',
    icon: <CIcon icon={cilStar} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: 'Login',
        to: '/login',
      },
      {
        component: CNavItem,
        name: 'Register',
        to: '/register',
      },
      {
        component: CNavItem,
        name: 'Error 404',
        to: '/404',
      },
      {
        component: CNavItem,
        name: 'Error 500',
        to: '/500',
      },
    ],
  }*/
]

export default _nav
